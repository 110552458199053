// import React from "react"
import { useStaticQuery, graphql } from "gatsby";

export function ScalingPlanSsdata () {
  // Ssで項目を追加したらqueryを追加
  const data = useStaticQuery(graphql`
  query PostScalingPlanQuery {
    allGoogleSpreadsheetSsdatabase4(sort: {fields: dataNum}) {
      edges {
        node {
					dataNum
          initialTradingCapital
          scalingVerificationPeriod
          targetPeriod
          scalingProfitTarget
          fundingAmount
          returnRate
          maxFundingAmount
          increaseAmountThirtyPercent
        }
      }
    }
  }
  `
  )
  const posts = data.allGoogleSpreadsheetSsdatabase4.edges; // 全スプレッドシート情報を設定
  return posts
}