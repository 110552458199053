import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import parse from "html-react-parser"


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー
import ent_l_icon from '@images/entry_l_icon.svg'; // 
import ent_r_icon from '@images/entry_r_icon.svg'; // 
import topimage01 from '@images/top-image01_2.webp';
import topimage02 from '@images/top-image02.webp';
import toptokuchou02 from '@images/top_tokuchou02.webp';
import toptokuchou05 from '@images/top_tokuchou05.webp';
import toptokuchou06 from '@images/top_tokuchou06.webp';
import toptokuchou07 from '@images/top_tokuchou07.webp';
import toptokuchou08 from '@images/top_tokuchou08.webp';
import abouttokuchou01 from '@images/about_tokuchou01.webp';

import charange01 from '@images/step1_icon.svg';
import charange02 from '@images/step2_icon.svg';
import charange03 from '@images/step3_icon.svg';

// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定
import { EntryClassSsdata } from "@components/entry-class-ssdata.js" // チャレンジコース情報
import { ScalingPlanSsdata } from "@components/scaling-plan-ssdata.js" // スケーリングプラン情報

const PropGuidance = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
 
  // Seo情報設定
  const pathName = 'guidance';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkGuidance = "/guidance#how";
  const LinkHowto = "/howto#how";
  const LinkHowto_demo = "/howto#demo";
  const LinkPayment = "/payment#pay";
  
  // チャレンジコースタブ生成
  const [openTab, setOpenTab] = React.useState(1); // 取引資金タブ切り替え用useState
  const [currentDisplay, setCurrentDisplay] = useState(1); // チャレンジコース切り替え用useState（mobile）
  let tabItem;
  let productArray = ['¥1,000,000', '¥3,000,000', '¥5,000,000', '¥10,000,000', '¥20,000,000'] // タブの金額設定
  
  // tabList作成function
  function makeTabList ( productArray ) {
    let tabArray = [];
    for (let i = 0; i < productArray.length; i++) {
      // 調整　資金調達額のタブの設定
      tabItem = <>
        <span className="mr-2 last:mr-0 flex-auto text-center mb-3 md:mb-0 border-[solid]  rounded-md">
          <a className={" rounded-[4.8px] md:text-xl text-base font-bold uppercase px-5 py-3 block leading-normal " + (openTab === (i + 1) ? "bg-[#EFAA00] text-white" : "bg-white text-black-400")} // タブのCSS設定
            onClick={e=> {e.preventDefault(); setOpenTab((i + 1));}} data-toggle="tab" href={"#link" + String(i + 1)} role="tablist">
            {productArray[i]}
          </a>
        </span>
      </>
      tabArray.push(tabItem)
    }
    let tabSubList = tabArray.map((result) => {
      return <li className="flex-[0_0_calc(50%_-_10px)] md:flex-[auto] m-[5px] ">{result}</li>
    });
    let tabList = <><ul className="flex list-none flex-wrap pt-3 pb-4 flex-row  px-5" role="tablist">{tabSubList}</ul></>
    return tabList
  }
  const tabList = makeTabList ( productArray );

  // チャレンジコース切り替え用ボタン設定（mobile）
  // 右ボタン処理
  const handleClickR = () => {
    setCurrentDisplay(currentDisplay => {
      return currentDisplay === 3 ? 1 : currentDisplay + 1;
    });
  };
  // 左ボタン処理
  const handleClickL = () => {
    setCurrentDisplay(currentDisplay => {
      return currentDisplay === 1 ? 3 : currentDisplay - 1;
    });
  };
  // ボタンで切り替える変数設定
  let entryClassCss1, entryClassCss2, entryClassCss3, textCss1, textCss2, textCss3, entryName;
  switch(currentDisplay) {
    case 1:
      entryClassCss1 = "";
      entryClassCss2 = "hidden";
      entryClassCss3 = "hidden";
      textCss1 = "text-[#02478E]";
      textCss2 = "";
      textCss3 = "";
      entryName = "STEP1";
      break;
    case 2:
      entryClassCss1 = "hidden";
      entryClassCss2 = "";
      entryClassCss3 = "hidden";
      textCss1 = "";
      textCss2 = "text-[#02478E]";
      textCss3 = "";
      entryName = "STEP2";
      break;
    case 3:
      entryClassCss1 = "hidden";
      entryClassCss2 = "hidden";
      entryClassCss3 = "";
      textCss1 = "";
      textCss2 = "";
      textCss3 = "text-[#02478E]";
      entryName = "認定トレーダー昇格";
      break;
    default:
      entryClassCss1 = "";
      entryClassCss2 = "hidden";
      entryClassCss3 = "hidden";
      textCss1 = "text-[#02478E]";
      textCss2 = "";
      textCss3 = "";
      entryName = "STEP1";
      break;
  }

  // チャレンジコース生成
  let entryData = EntryClassSsdata(); // チャレンジコース情報読み込み
  let fieldArray = Object.keys(entryData[0].node); // スプシのキー値を配列化
  fieldArray = fieldArray.filter(field => field !== 'fundingAmount' && field !== 'dataNum'); // 不要なキー値を削除
  let paramArray = ['/?=001', '/?=002', '/?=003', '/?=004', '/?=005'] // エントリーするbuttonの遷移先
  let entryClassSsdata = []; // ５パターンの表を配列化
  for ( let i = 0; i < 5; i++ ) {
    setArray = fieldArray.map((result) => { 
      // 調整　モーダル内のテキストの設定
      let item = <>
          <div className="min-h-[300px] md:min-h-[200px] break-words bg-white w-full rounded py-10 px-5 text-left">
            <div className="flex-auto">            
              {entryData[1].node[result]}
            </div>
          </div>
        </>;
      let modalData = ModalItem(item)
      let modalCss1, modalCss2, modalCss3, modalCss4;
      if (result === 'entryClass' ) {
        modalCss1 = "!hidden";
        modalCss2 = "!bg-white text-[#02478E] !px-0 !justify-center";
        modalCss3 = "!bg-white text-[#02478E] !text-[16px] !px-0";
        modalCss4 = "!bg-white text-[#02478E] !text-[18px] !px-0";
      }
      // 調整　表・各行の設定
      return  <>
                {/* PC用リスト */}
                <tr className="hidden md:table-header-group">
                  <th className={"h-[120px] p-8 text-xs bg-white flex justify-between w-full " + modalCss2}><span className="flex items-center text-left">{entryData[0].node[result]}</span><div className={modalCss1}>{modalData}</div></th>{/* 1列目 */}
                  <th className={"h-[120px] p-8 text-xs bg-[#E6F3FF] md:table-cell w-1/5 " + modalCss2}>{entryData[(i * 3) + 2].node[result]}</th>{/* 2列目 */}
                  <th className={"h-[120px] p-8 text-xs bg-white md:table-cell w-1/5 " + modalCss3}>{entryData[(i * 3) + 3].node[result]}</th>{/* 3列目 */}
                  <th className={"h-[120px] p-8 text-xs bg-[#E6F3FF] md:table-cell w-1/5 " + modalCss4}>{entryData[(i * 3) + 4].node[result]}</th>{/* 4列目 */}
                </tr>
                {/* MB用リスト */}
                <tr className="md:hidden">
                  <th className={"h-[120px] p-6 text-xs bg-white flex justify-between w-full " + modalCss1}><span className="flex items-center w-[100px]  text-left">{entryData[0].node[result]}</span><div className={modalCss1}>{modalData}</div></th>{/* 1列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss1 + " " + modalCss1}>{entryData[(i * 3) + 2].node[result]}</th>{/* 2列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss2 + " " + modalCss1}>{entryData[(i * 3) + 3].node[result]}</th>{/* 3列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss3 + " " + modalCss1}>{entryData[(i * 3) + 4].node[result]}</th>{/* 4列目 */}
                </tr>
              </>
    });
    // 調整　チャレンジコース表全体
    pushArray = <>
      <div className={(openTab === (i + 1) ? "block " : "hidden ") + "max-w-5xl  mx-auto px-5 pt-0  ent_font"}> 
        <table className="text-center flow-root md:table">
          {/* MB用追加(PC非表示) */}
          <tr className="test1 md:hidden flex justify-between bg-white">
            <button onClick={handleClickL} className="p-5">
              <img alt="" src={ent_l_icon}/> {/* 調整　左マークアイコンに差し替える */}
            </button>
            <div className="test2 text-[#02478E] font-bold py-6"><p className="text-xs">チャレンジコース</p><p className="text-[24px]">{entryName}</p></div> {/* 調整　テキストCSSを調整 */}
            <button onClick={handleClickR} className="p-5">
              <img alt="" src={ent_r_icon}/> {/* 調整　右マークアイコンに差し替える */}
            </button>
          </tr>
          <div className="test5 md:hidden flex justify-between bg-[#F6F6F6] px-10 py-5 text-xs font-bold text-[#B9B9B9]">          
            <p className={textCss1}>1STEP</p><p>＞</p><p className={textCss2}>2STEP</p><p>＞</p><p className={textCss3}>トレーダー</p>
          </div>
          {/* 共通データ */}
          {setArray}
          {/* MB用追加(PC非表示) */}
          {/*  <tr>
            <th className={"p-8 text-xs flex justify-between bg-white text-left"}>{/* 1列目 
              <p>新規無料登録をする</p>  
            </th>
            <th className={"p-8 text-xs md:table-cell bg-[#E6F3FF]"} colSpan="3">{/* 2列目 
              <a href={paramArray[i]} className="whitespace-nowrap p-2 rounded-full text-xs text-white bg-[#EFAA00] px-4">登録する</a> {/* 調整　エントリーするボタン 
            </th>
          </tr>*/}
        </table>
      </div>
    </>;
    entryClassSsdata.push(pushArray)
  }

  // スケーリングプランタブ生成
  const [openTab2, setOpenTab2] = React.useState(1); // 取引資金タブ切り替え用useState
  let tabItem2;
  let productArray2 = ['¥1,000,000', '¥3,000,000', '¥5,000,000', '¥10,000,000', '¥20,000,000'] // タブの金額設定
  
  // tabList作成function
  function makeTabList2 ( productArray2 ) {
    let tabArray2 = [];
    for (let i = 0; i < productArray2.length; i++) {
      // 調整　資金調達額のタブの設定
      tabItem2 = <>
        <span className="mr-2 last:mr-0 flex-auto text-center mb-3 md:mb-0 border-[solid]  rounded-md">
          <a className={" rounded-[4.8px] md:text-xl text-base font-bold uppercase px-5 py-3 block leading-normal " + (openTab2 === (i + 1) ? "bg-[#EFAA00] text-white" : "bg-white text-black-400")} // タブのCSS設定
            onClick={e=> {e.preventDefault(); setOpenTab2((i + 1));}} data-toggle="tab" href={"#link" + String(i + 1)} role="tablist">
            {productArray2[i]}
          </a>
        </span>
      </>
      tabArray2.push(tabItem2)
    }
    let tabSubList2 = tabArray2.map((result) => {
      return <li className="flex-[0_0_calc(50%_-_10px)] md:flex-[auto] m-[5px] ">{result}</li>
    });
    let tabList2 = <><ul className="flex list-none flex-wrap pt-3 pb-4 flex-row  px-5" role="tablist">{tabSubList2}</ul></>
    return tabList2
  }
  const tabList2 = makeTabList2 ( productArray2 );

  // スケーリングプラン表生成
  let scalingDate = ScalingPlanSsdata(); // スケーリングプラン情報読み込み
  fieldArray = Object.keys(scalingDate[0].node); // スプシのキー値を配列化
  fieldArray = fieldArray.filter(field => field !== 'dataNum' && field !== 'increaseAmountThirtyPercent'); // 不要なキー値を削除
  
  // 表へ付与するClassの設定
  const classArray = [
    ["","","hidden","","","","!border-r-0"],
    ["!border-b-0","","hidden","","","","!border-r-0"],
    ["","","!border-b-0 hidden","","font-bold text-[#1F55A6]","font-bold text-xl !border-b-0 text-[#1F55A6]","font-bold !border-r-0 !border-b-0 text-[#1F55A6]"],
    ["","","hidden","","font-bold text-[#1F55A6]","",""],
    ["","","hidden","","font-bold text-[#1F55A6]","",""],
    ["","!border-b-0","hidden","","font-bold !border-b-0 text-[#1F55A6]","",""]
  ]
  const classArrayMB = [
    ["!min-w-[100px]","!min-w-[130px] hidden","!min-w-[180px]","!min-w-[200px]","!min-w-[100px]","!min-w-[150px] !border-r-0"],
    ["","hidden","","","","!border-r-0"],
    ["","hidden","","font-bold text-[#1F55A6]","font-bold text-xl !border-b-0 text-[#1F55A6]","font-bold !border-r-0 !border-b-0 text-[#1F55A6]"],
    ["","hidden","","font-bold text-[#1F55A6]","",""],
    ["","hidden","","font-bold text-[#1F55A6]","",""],
    ["!border-b-0","hidden","","font-bold !border-b-0 text-[#1F55A6]","",""]
  ]
  // 表のセル結合設定
  const cellMergingArray = [
    ["","","","","","",""],
    [5,"","","","","",""],
    ["","",4,"","",4,4],
    ["","","","","","",""],
    ["","","","","","",""],
    ["","","","","","",""]
  ]
  const cellMergingArrayMB = [
    ["","","","","",""],
    ["","","","","",""],
    ["",4,"","",4,4],
    ["","","","","",""],
    ["","","","","",""],
    ["","","","","",""]
  ]
  // PC画面用配列
  let scalingArray1 = [];
  let scalingArray2 = [];
  let scalingArray3 = [];
  let scalingPlanTable = [];
  // MB画面用配列
  let scalingArrayMB1 = [];
  let scalingArrayMB2 = [];
  let scalingArrayMB3 = [];
  let scalingPlanTableMB = [];
  
  // 先頭行生成
  for ( let i = 0; i < 7; i++ ) {
    if( scalingDate[0].node[fieldArray[i]] !== "null" ){
      setArray =  <td rowspan={cellMergingArray[0][i]} className={"p-[16px] " + classArray[0][i]}>{scalingDate[0].node[fieldArray[i]]}</td>
      scalingArray1.push(setArray);
      if (i !== 0) {
        setArray =  <td rowspan={cellMergingArrayMB[0][i - 1]} className={"p-5 " + classArrayMB[0][i - 1]}>{scalingDate[0].node[fieldArray[i]]}</td>
        scalingArrayMB1.push(setArray) 
      }
    } 
  }
  const firstRow = <tr className="font-semibold text-xs bg-white">{scalingArray1}</tr>

  // ２行目以降生成
  for ( let k = 0; k < 5; k++ ) {
    let scalingText;
    scalingArray2 = []
    scalingArray2.push(firstRow) 
    scalingArrayMB2 = []
    let firstRowMB =  <>
                        <tr className="font-semibold text-xs bg-white"><td className="p-[16px]" colspan="7">{scalingDate[0].node[fieldArray[0]]}</td></tr>
                        <tr className=""><td className="p-5" colspan="7">{scalingDate[1 + k * 5].node[fieldArray[0]]}</td></tr>
                        <tr className="font-semibold text-xs bg-white">{scalingArrayMB1}</tr>
                      </>
    scalingArrayMB2.push(firstRowMB) 
    for ( let j = 1; j < 6; j++ ) {
      scalingText = "取引資金" + scalingDate[1 + k * 5].node[fieldArray[0]] + "の場合のスケーリングプラン例";
      let scalingArray1 = []
      let scalingArrayMB1 = []
      for ( let i = 0; i < 7; i++ ) {
        if( scalingDate[j].node[fieldArray[i]] !== "null" ){
          setArray =  <td rowspan={cellMergingArray[j][i]} className={"p-[16px] " + classArray[j][i]}>{parse(scalingDate[j + k * 5].node[fieldArray[i]])}</td>
          scalingArray1.push(setArray);
          if (i !== 0) {
            setArray =  <td rowspan={cellMergingArrayMB[j][i - 1]} className={"p-5 " + classArrayMB[j][i - 1]}>{parse(scalingDate[j + k * 5].node[fieldArray[i]])}</td>
            scalingArrayMB1.push(setArray) 
          }
        } 
      }
      scalingArray2.push(<tr className="">{scalingArray1}</tr>) ;
      scalingArrayMB2.push(<tr className="">{scalingArrayMB1}</tr>) ;
    }
    scalingArray3.push(
      <div className={(openTab2 === (k + 1) ? "block " : "hidden ")}>
        <h3 className="pr-5 mx-auto max-w-xl md:max-w-5xl md:text-2xl text-xl font-black mb-5 text-left text-[#1F55A6] ">{scalingText}</h3>
        <table>{scalingArray2}</table>
      </div>) 
    scalingArrayMB3.push(
      <div className={(openTab2 === (k + 1) ? "block " : "hidden ")}>
        <h3 className="pr-5 mx-auto max-w-xl md:max-w-5xl md:text-3xl text-xl font-black mb-5 text-left text-[#1F55A6] ">{scalingText}</h3>
        <table>{scalingArrayMB2}</table>
      </div>) 
  }
  scalingPlanTable = <div className="mx-auto max-w-5xl px-5 scale_table scale_td w-full md:block hidden">{scalingArray3}</div>
  scalingPlanTableMB = <div className="max-w-5xl mx-auto px-5 scale_table scale_td_sp w-full md:hidden block overflow-scroll">{scalingArrayMB3}</div>
 
  return (
  <>
   <Layout> 
      {seoTag}
      <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[330px]  xl:overflow-visible overflow-hidden">
          <div className="pb-10 pt-0 md:pt-10  pt-0 md:pt-10 max-w-[800px] md:absolute md:left-5 md:top-[0%]">
            <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">サービスのご案内</h2>
            <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeは日本発のプロップファームとして<br></br>独自の審査基準を設けております。</h3>
            <p className="md:text-base text-base  font-black md:max-w-[500px] xl:max-w-[600px]">また、トレーダーの皆様にリスクなしで取引していただけるだけでなく<br></br>
               出金までの手続き状況をすべてご覧いただけるようなプラットフォームを構築しております。
            </p>
          </div>
          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
            <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" 
              initial={{ opacity:0, x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/> </motion.div>
          </div>
        </div>
      </div>
      {/* サブメニュー */}
      <div className="bg-[#F6F6F6] "><a id="how" className="jump_point"></a>
        <div className="max-w-5xl mx-auto flex px-5 items-center">
          <a href={LinkGuidance} className="md:text-base text-xs text-center md:py-4 py-2 px-2 md:px-10 w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">サービスの<br className="res_br"></br>内容</a>      <a href={LinkHowto} className="md:text-base text-xs text-center md:py-4 py-4 px-5 md:px-10  w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">使い方</a>      <a href={LinkPayment} className="md:text-base text-xs text-center md:py-4 py-4 px-2 md:px-10  w-1/3 bg-white ml-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">出金について</a>
        </div>
      </div>
      <div className="px-5 max-w-xl mx-auto md:max-w-5xl  mt-[70px] ">
      <a id="twostep" className="jump_point"></a>
        {/* チャレンジ期間・表 */}
        <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">認定トレーダーになるには</h3>
        <p>
           Jsut tradeは、資金を運用して利益を得ることができる「認定トレーダー」に昇格するまでのプロセスを、シンプルな2STEPに設定することで多くの方がトレーダーとして活躍するチャンスを用意しました。<br></br>
           トレーダーに昇格後は、<span className="font-bold text-[#EFAA00]">損失責任なし</span>で取引を行い、取引で得た<span className="font-bold text-[#EFAA00]">利益の最大90%</span>を受け取ることができます。
        </p>
      </div>
      <div className="pb-[70px] py-12">
      <div className="max-w-5xl mx-auto px-5 xl:block hidden md:mb-[50px]">
          <table>
            <tr>
            <td colSpan={2} className="pl-5 pt-5 w-2/3  align-top  border-t-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]" ><p className="text-2xl text-center text-[#1F55A6] font-bold ">チャレンジ期間</p></td>
            <td className="pl-5 pt-5 w-1/3  align-top  " ><p className="text-2xl text-center text-[#1F55A6] font-bold ">認定トレーダーに昇格</p></td>
            </tr>
            <tr>
            <td className="relative w-1/3  p-[20px]  align-top border-b-[8px] border-l-[8px] border-[#F6F6F6]">
            <div className="rounded-md bg-[#F6F6F6]  p-5 "><p className="text-xl font-bold mb-3 text-center text-[#1F55A6] "><img className="mb-4 mx-auto max-w-[90px]" src={charange01}></img></p>
            <p className="text-base min-h-[170px]">STEP1では<span className="font-bold underline text-[#DF5F6A] ">30日間</span>という取引期間のなかで目標利益を目指していただきます。<br></br><br></br>指定された最低取引回数や最大損失などの条件をクリアできれば次のSTEPに進むことができます。</p>
            </div>
            <p className="before:top-[40%] before:right-[-3%] before:absolute before:content-[url(@images/2step_yajirushi.svg)] before:text-black before:text-base before:z-50"></p>
            </td>
            <td className="relative w-1/3  p-[20px] pr-[20px] align-top border-b-[8px] border-r-[8px] border-[#F6F6F6]"><div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold  mb-3 text-center text-[#1F55A6] "><img src={charange02} className="mb-4 mx-auto max-w-[90px]"></img></p>
            <p className="text-base  min-h-[170px]">こちらでは更に取引期間を延ばし、<span className="font-bold underline text-[#DF5F6A] ">60日間</span>で目標利益を目指していただきます。<br></br><br></br>指定の条件を達成するとJust trade「<span className="text-[#DF5F6A] font-bold">認定トレーダー</span>」に昇格できます。</p></div>
            <p className="before:top-[40%] before:right-[-3%] before:absolute before:content-[url(@images/2step_yajirushi.svg)] before:text-black before:text-base before:z-50"></p>
            </td>
            <td className="w-1/3  pl-[20px] pt-5 align-top"><div className="rounded-md bg-[#1F55A6] text-white p-5">
            <p><img src={charange03} className="mb-4 mx-auto mb-2 mx-auto max-w-[90px]"></img></p>
             
            <p className="text-bases">「認定トレーダー」に昇格後は、取引で得た利益の<span className="font-bold underline text-[#FFE4A0] ">80%</span>を受け取ることができます。<br></br><br></br>また、スケーリングプランの条件をクリアすると利益の還元率を<br></br><span className="font-bold underline text-[#FFE4A0] ">最大90%</span>まで増やすことができます。
             </p></div></td>
            </tr>
          </table>
        </div>
        

        <div className="max-w-5xl mx-auto px-5 xl:block xl:hidden block">
          <table>
            <tr>
            <td className="align-top p-5 relative pb-[50px] border-t-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]"><p className="text-xl text-center text-[#1F55A6] font-bold mb-5">チャレンジ期間</p>
          <div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold mb-3 text-center text-[#1F55A6] "><img className="mb-4 mx-auto max-w-[90px]" src={charange01}></img></p>
            <p className="text-base">STEP1では<span className="font-bold underline text-[#DF5F6A] ">30日間</span>という取引期間のなかで目標利益を目指していただきます。<br></br><br></br>指定された最低取引回数や最大損失などの条件をクリアできれば次のSTEPに進むことができます。</p>
            <p className="before:z-[800] before:bottom-[-2%] before:right-[45%] before:absolute before:content-[url(@images/2step_yajirushi-sp.svg)] before:text-black before:text-base"></p>
            </div></td>
            </tr>
            <tr>
         
            <td className="align-top p-5 relative pb-[50px] border-b-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]">
              <div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold  text-[#1F55A6] mb-3 text-center"><img className="mb-4 mx-auto max-w-[90px]" src={charange02}></img></p>
            <p className="text-base">こちらでは更に取引期間を延ばし、<span className="font-bold underline text-[#DF5F6A] ">60日間</span>で目標利益を目指していただきます。<br></br><br></br>指定の条件を達成するとJust trade「<span className="text-[#DF5F6A] font-bold">認定トレーダー</span>」に昇格できます。</p>
            <p className=" before:bottom-[-5%] before:right-[45%] before:absolute before:content-[url(@images/2step_yajirushi-sp.svg)] before:text-black before:text-base"></p></div></td>
            </tr>
            <tr>
           
            <td className="align-top p-5 pt-10 relative">
            <p className="text-2xl text-center text-[#1F55A6] font-bold mb-5">認定トレーダーに昇格</p>
            <div className="rounded-md bg-[#1F55A6] text-white p-5 ">
            <p><img src={charange03} className="mb-4 mx-auto mb-2 mx-auto max-w-[90px]"></img></p>
            <p className="text-base">「認定トレーダー」に昇格後は、取引で得た利益の80%を受け取ることができます。<br></br><br></br>また、スケーリングプランの条件をクリアすると利益の還元率を最大90%まで増やすことができます。</p>
            </div></td>
            </tr>
          </table>
        </div>  

      </div>

      {/* チャレンジコース・表 */}
      <div className="bg-[#02478E] py-20">
      <a id="cost" className="jump_point"></a>
        <div className="max-w-5xl mx-auto px-5">
          <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-white">5タイプのチャレンジコース</h3>
          <p className="text-white mb-12 ">Just tradeのプロップファームはチャレンジコースを5タイプ用意しています。<br></br>
            各クラスはチャレンジ費用によって区分けされ、それぞれ取引資金が100万円から2,000万円まで幅があり、利益目標などの条件も変わってきます。<br></br>
            下記表に記載の条件を確認して、自分のレベルに合わせてチャレンジコースを選びましょう。<br></br>
            また、一つのアカウントで複数のクラスを申請することも可能ですので、一度エントリーしてアカウントを登録すれば、本人確認手続きなどの面倒な手続きを何度もする必要はありません。</p>
        </div>
        <div className="text-center  text-2xl text-white">
          <p>取引金額によって変わる条件</p>
        </div>
        <div className="max-w-xl mx-auto md:max-w-5xl mt-[20px] "> {tabList} {entryClassSsdata} </div>
      </div>
      {/* 特徴 */}
      <div className="max-w-xl mx-auto md:max-w-5xl px-5 relative mt-[50px] ">
        <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6] pt-10">最高の取引環境を提供</h3>
        <p className="mb-[100px] ">初心者から経験者まで、トレーダーが快適に取引を行える環境を用意しております。</p> {/* 特徴詳細1*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">業界最良基準</h4>
            <div className="block  md:hidden mb-5">
        <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
            <p className=" md:max-w-[110px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto pr-4" alt="" src={abouttokuchou01}/> </p>
            </div>
          </div>
            <p>手数料なし、非常に低いスプレッドやスワップポイントでトレーダーは業界最良基準の好条件で取引可能です。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[110px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto pr-4" alt="" src={abouttokuchou01}/> </p>
            </div>
          </div>
        </div>
        {/* 特徴詳細2*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">レバッジ最大25倍</h4>
            <div className="block  md:hidden mb-5">
               <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
                <p className=" md:max-w-[90px] max-w-[70px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou05}/> </p>
            </div>
          </div>
            <p>全てのチャレンジコースで最大25倍のレバレッジで取引することが可能です。<br></br> 例えば10万円の資金を25倍のレバレッジを利用すると、1,000万円として取引することが可能ですので資金に対して大きなリターンを見込めます。<br></br>
              プロップファームの利点は例え損失を出しても、損失責任が無いため、トレーダーはリスクを取ることができる点です。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[90px] max-w-[70px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou05}/> </p>
            </div>
          </div>
        </div>
        {/* 特徴詳細3*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">無料で利用できるデモトレード完備</h4>
            <div className="block  md:hidden mb-5">
               <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
               <p className=" md:max-w-[70px] max-w-[80px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou02}/> </p>
            </div>
          </div>
            <p className="mb-5">チャレンジ費用を支払わずとも、何度でも試せる無料トライアルができるデモトレードモードを用意しております。<br></br> チャレンジ期間に参加する前に練習をしたい方や、取引プラットフォームに慣れたい方はデモトレードモードの利用をお勧めします。
              </p> <a href={LinkHowto_demo} className="duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[210px] hover:tracking-widest">デモトレードの始め方はこちら</a></div>
              <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[70px] max-w-[80px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou02}/> </p>
            </div>
          </div>
        </div>
        {/* 特徴詳細4*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">人気のあるプラットフォーム</h4>
            <div className="block  md:hidden mb-5">
               <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
               <p className=" md:max-w-[80px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou06}/> </p>
            </div>
          </div>
            <p>人気のプラットフォーム、MT5を採用。<br></br>Windows版やMac版、モバイル版など、環境にあったMT5をマイページからいつでも無料でダウンロード可能です。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[80px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou06}/> </p>
            </div>
          </div>
        </div>
        {/* 特徴詳細5*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">様々な取引ツールに対応</h4>
            <div className="block  md:hidden mb-5">
               <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
               <p className=" md:max-w-[90px] max-w-[80px] mx-auto"> <img className=" mx-auto h-auto" alt="" src={toptokuchou07}/> </p>
            </div>
          </div>
            <p>EA（自動売買システム）のみならず、PAMMやMAMなどの取引にも対応しております。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[90px] max-w-[80px] mx-auto"> <img className=" mx-auto h-auto" alt="" src={toptokuchou07}/> </p>
            </div>
          </div>
        </div>
        {/* 特徴詳細6*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">メジャー通貨ペア取引可能</h4>
            <div className="block  md:hidden mb-5">
               <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
               <p className=" md:max-w-[90px] max-w-[80px] mx-auto"> <img className=" mx-auto h-auto" alt="" src={toptokuchou08}/> </p>
            </div>
          </div>
            <p>USDJPY、EURUSDなどのメジャー通貨ペアから自由に取引する銘柄を選ぶことができます。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[90px] max-w-[80px] mx-auto"> <img className=" mx-auto h-auto" alt="" src={toptokuchou08}/> </p>
            </div>
          </div>
        </div>
      </div>
      {/*スケーリングプラン*/}
      <div className="bg-[#F6F6F6] pt-[90px] pb-[40px]"> 
      <a id="scale" className="jump_point"></a>
        <div  className="max-w-xl mx-auto md:max-w-5xl px-5 md:relative md:h-[360px] xl:overflow-visible overflow-hidden">
          <h2 className="md:text-6xl text-4xl  font-black text-left pt-5">スケーリングプラン</h2>
          <div className="py-5 max-w-[800px] md:absolute md:left-5 md:top-[25%]">
            <div className="md:text-base text-base md:leading-6 font-black max-w-[520px] ">
              <p>トレーダー昇格後に2ヶ月以上利益を出し、かつ取引資金を10%増やすことができた場合に、初回取引資金の30%が新に取引資金に付与され最大4,000万まで増額させることができます。<br></br>
                さらに利益の還元率も通常80%から最大90%に増加します。
                </p>
            </div>
            <div className="md:hidden block max-w-[650px]">
              <motion.div className="mx-auto text-center md:mt-[-70px] md:ml-[40px] mt-10" initial={{ opacity:0 ,x:100 }} whileInView={{
                opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> <img className="mb-5 mx-auto h-auto" alt="" src={topimage02}/> </motion.div>
            </div>
          </div>
          <div className="hidden md:block max-w-[650px] md:absolute right-[-15%]">
            <motion.div className=" mx-auto text-center md:mt-[-70px] md:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{
              opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> <img className="mb-5 mx-auto h-auto" alt="" src={topimage02}/> </motion.div>
          </div>
        </div>
        <div className="mx-auto max-w-xl md:max-w-5xl w-full">
          {tabList2}
        </div>
        {scalingPlanTable}
        {scalingPlanTableMB}
      </div>
      {/* 固定フッター */}
      {fixedItem} 
    </Layout>
  </>
  )
}
export default PropGuidance

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[39%] md:mt-[13%] max-w-[320px] md:max-w-[400px]  -ml-[160px] md:-ml-[200px] touch-none" style={{top: startYOffset.current}}>
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px] md:max-w-[400px] font-normal">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-0 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
